<template>
	<div></div>
</template>

<script>
	export default {
		created() {
			this.LoginByCIAM('登录中请稍后', 10)
		}
	}
</script>

<style>
</style>